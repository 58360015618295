// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oferta-klienci-indywidualni-js": () => import("./../../../src/pages/oferta-klienci-indywidualni.js" /* webpackChunkName: "component---src-pages-oferta-klienci-indywidualni-js" */),
  "component---src-pages-oferta-klienci-korporacyjni-js": () => import("./../../../src/pages/oferta-klienci-korporacyjni.js" /* webpackChunkName: "component---src-pages-oferta-klienci-korporacyjni-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

