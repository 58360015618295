module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"wrapperStyle":"max-width: 1200px; max-height: 400px; overflow: hidden; text-align: center","linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-73555040-2","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pomoc w angielskim","short_name":"Pomoc w angielskim","start_url":"/","background_color":"#ffffff","theme_color":"#cce5ff","display":"minimal-ui","icon":"src/assets/flag.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4d4858abba06f972045ba5ef59f779b8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js","manualInit":true,"enableIdentityWidget":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"yellow":"#ffd200","lightGrey":"#f7f7f7","defaultTypeColor":"#0d0d0d","tableBorderGrey":"#cccccc","tableHeaderBlue":"#5798ea","tableRowGrey":"#f3f3f3","greyTypeColor":"#aaaaaa","black":"#242424","blueGradient":"linear-gradient(to right, #43a7f0, #3a96ef)","background":"rgba(240, 240, 240, 0.95)","menuHoverBackground":"rgba(255, 255, 255, 1)","yellowBoxBackground":"#ffff99","blueBoxBackground":"#cce5ff","greyBoxBackground":"#f1f1f1","blockquoteBackground":"#fafafa","blockquoteBorder":"#ededed","fatBlueBoxBackground":"#f1f8fe","cardGridPaddingThreshold":768,"breakPoints":{"smMin":"500px","mdMin":"800px","lgMin":"1200px","xlMin":"1600px","xsMax":"499.98px","smMax":"799.98px","mdMax":"1199.98px","lgMax":"1599.98px"},"boxShadow":"0 3px 3px 0 rgba(0, 0, 0, 0.2)","hoverBoxShadow":"0 10px 20px 0 rgba(0, 0, 0, 0.2), 0 15px 45px 0 rgba(0, 0, 0, 0.19)","boxPaddingSides":1,"boxPaddingTopBottom":1,"boxMarginTopBottom":1,"boxMarginSides":-0.5,"tileGutter":"1rem","minSidePadding":{"paddingLeft":"var(--min-side-padding)","paddingRight":"var(--min-side-padding)"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1116789168775111"},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"publicApiKey":"Zjk3MDgwZjMtNzgzNS00ZDQyLTlhMTEtNjIyN2IxZDM0MzBhNjM3NjY1MzIxOTUxNTYxNzgx","defaultLang":"pl","locales":{"pl":{"billing":{"address":"Adres wysyłki - ze względów technicznych prosimy wpisać dowolny adres i numer lokalu (np. ul. Zakątek 1, Poznań)"}}},"currency":"pln","openCartOnAdd":false,"useSideCart":true,"innerHTML":"\n                    <billing section=\"bottom\">\n                        <fieldset class=\"snipcart-form__set\">\n                            <div class=\"snipcart-form__field\">\n                                <div class=\"snipcart-form__field-checkbox\">\n                                    <snipcart-checkbox name=\"subscribeToNewsletter\"></snipcart-checkbox>\n                                    <snipcart-label for=\"subscribeToNewsletter\" class=\"snipcart__font--tiny snipcart-form__label--checkbox\">\n                                        Zapisz się na newsletter, żeby otrzymywać wskazówki dot. kariery oraz j. angielskiego\n                                    </snipcart-label>\n                                </div>\n                            </div>\n                        </fieldset>\n                    </billing>"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
